.home {
  width: 100%;
  margin:0;
  padding: 0;
  height: 200vh;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Carouselslider{
  margin-top: 20px;
  height: 50%;
}

