.card{
    justify-content:center;
    align-items:center;
    flex-direction: column;
    position:relative;
    margin: 50px;
    width: 30%;
    height: 380px;
    border-radius: 15px;
}

.card h1{
    font-size:25px;
}

.card img {
    margin : 10px;
    height:200px;
    width: 250px;
  }

  .card button{
    border-radius: 15px;
    background: rgb(146, 146, 167);
  }