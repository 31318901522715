.navbar {
    width: 100%;
    height: 170px;
    background-image: linear-gradient(#6b6666, #f7f4f4);
    position : fixed;
    top:0;
    left:0;
    z-index:10000;
  }



  /*left*/

  .Leftside{ 
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .Leftside .menu a {
    color: rgb(10, 9, 9);
    text-decoration: none;
    margin-left: 39px;
    font: bold 1.2em 'Courier New', monospace;
  }

  .Leftside .menu a:hover{
    color:#2d49c7;
  }

  .mobile-toggle{
    display:none;
  }

  .closeMenu {
    display:none;
  }

  /* middle */

  .Middle {
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
  }

  .Middle img{

    height:180px;
  }
  
  /*right*/

  .Rightside {

    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .Rightside .right-item{
    display: flex;
    align-items: center;
    margin:30px;

  }
  
  .shopping.cart{
    margin:10px;
  }

  .member{
    margin:10px;
  }



  .Rightside .search-box{
    background : none;
    height:45px;
    border-radius: 30px;
    padding: 8px;                                                                                                                                                                               
  }

  .Rightside .search-box:hover{
    background: #332f2f;
  }

  .Rightside .search-box:hover .search-txt{
    width: 200px;
    padding : 0 6px;
    background: #332f2f;
  }

  .Rightside .search-box:hover .search-btn{
    background:#635a5a;
  }

  .Rightside .search-btn{

    color: #000000;
    width : 30px;
    height: 30px;
    background : none;
    float: right;
    border-radius: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
  }

  .Rightside .search-txt{
    border:none;
    background: none;
    outline:none;
    float:left;
    padding :0;
    color:white;
    font-size:16px;
    transition:0.4s;
    line-height:30px;
    width: 0px;
  }

  @media only screen and (max-width: 768px) {
    div.navbar{
      height:70px;
    }
    div.Middle{
      height:auto;
      width:auto;
      align-items:center;
      position: relative;
    }
    div.menu {
      flex-direction: row;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      justify-content: flex-start;
      padding-top: 30px;
      transform: translateX(-100%);
      transition: transform 0.5s ease;
    }
    div.menu.active{
      transform: translateX(0);
    }
    div.mobile-toggle{
      display:block;
    }
    div.right-item{
      margin:10px;
    }
  }